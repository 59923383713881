import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Typography from '@mui/material/Typography';



const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})



analytics.page()

const Montessori= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Box>
      <Container  maxWidth={0.78}  paddingTop={'0 !important'}>
          <Grid>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align='center'> 
                Montessori
              </Typography><br />
          <Typography component='p' align={'justify'} color="text.secondary">
      <br></br>
“Tell me and I forget. Teach me and I remember. Involve me and I learn” – Benjamin Franklin
<br></br>
<br></br>
Montessori education enables children to develop the fundamental capacities that they need, to become happy and fulfilled adults who contribute to the society. The Montessori method allows children to be spontaneous learners who enjoy and carry out independent learning opportunities effortlessly. It gives children the opportunity to work with real materials, in real life situations. Our goal is to teach children skills that can be used outside the classroom.
<br></br>
At Montessori, students work with stimulating materials to develop concentration and a love for learning. Children are divided into the following categories on the basis of their age – Beginners, Juniors and Seniors. Each section is called an Environment which is a cohort of mixed ages.  The Montessori system develops a sense of responsibility and independence in children. Activity-based learning is at the core of this methodology. <br/>
At I-5 Academy we sow the seeds of curiosity in children so that they are lifelong learners. Opportunities are provided to help the child discover the world on his own, through experience and exposure. This helps him to go out and face life’s challenges with courage and confidence.
      </Typography>
          </Grid>

        </Container>
        <Container maxWidth={0.78}  paddingTop={'0 !important'}>
          <Form />
          
        </Container>
      </Box>
    </Main>
  );
};

export default Montessori;
